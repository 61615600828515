import { lazy, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';

import { fetchGeneral, selectGeneralIsLoading, selectGeneralTags } from 'store/slices/GeneralSlice';
import { fetchHomepage, selectHomepageIsLoading } from 'store/slices/HomepageSlice';
import { selectFeaturesIsLoading, fetchFeatures } from 'store/slices/FeaturesSlice';
import { fetchPosts } from 'store/slices/BlogSlice';
import { fetchLocations } from 'store/slices/LocationsSlice';
import { fetchProductsData } from 'store/slices/PricingSlice';

import ROUTES from 'constants/routes';
import { generateMetaTags } from 'helpers';

import Layout from './Layout/Layout';
import FZF from 'pages/404';
import LandingPage from 'pages/LandingPage';
import UseCases from 'pages/UseCases';
import BrandAssets from 'pages/BrandAssets';

import BasicAuth from './components/BasicAuth/BasicAuth';
import LoadingOverlay from 'components/common/LoadingOverlay';

const Home = lazy(() => import('pages/Home'));
const ProductPage = lazy(() => import('pages/Product'));
const Features = lazy(() => import('pages/Features'));
const MainProxyLocation = lazy(() => import('pages/ProxyLocations/MainProxyLocations'));
const CurrentProxyLocation = lazy(() => import('pages/ProxyLocations/CurrentProxyLocations'));
const Company = lazy(() => import('pages/ProxyLocations/MainProxyLocations'));
const Pricing = lazy(() => import('pages/Pricing'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const FAQPage = lazy(() => import('pages/FAQ'));
const Blog = lazy(() => import('pages/Blog'));
const CurrentPost = lazy(() => import('pages/Blog/CurrentPost'));
const TermsAndConditions = lazy(() => import('pages/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('pages/RefundPolicy'));
const NotFoundWrapper = lazy(() => import('pages/404/NotFoundWrapper'));

const App = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [cookies] = useCookies(['cookieAgreement']);
    const isCookieAgree = cookies.cookieAgreement;

    const generalIsLoading = useSelector(selectGeneralIsLoading);
    const homepageIsLoading = useSelector(selectHomepageIsLoading);
    const featuresIsLoading = useSelector(selectFeaturesIsLoading);

    const homeTags = useSelector(selectGeneralTags);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchGeneral());
        // @ts-ignore
        dispatch(fetchHomepage());
        // @ts-ignore
        dispatch(fetchFeatures());
        // @ts-ignore
        dispatch(fetchLocations());
        // @ts-ignore
        dispatch(fetchProductsData());
        dispatch(
            // @ts-ignore
            fetchPosts({ page: 1 }),
        );
    }, [dispatch]);

    ReactGA.initialize(`${process.env.REACT_APP_GA_TAG}`);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname })
    }, [location]);

    const [isMavaReady, setIsMavaReady] = useState(false);

    useEffect(() => {
        if (!generalIsLoading && !homepageIsLoading && !featuresIsLoading) {
            // Trigger Mava script loading
            const newEvent = new Event('loadMavaWebchat');
            window.dispatchEvent(newEvent);

            // Listen for Mava ready event
            const handleMavaReady = () => {
                setIsMavaReady(true);
            };

            window.addEventListener('mavaReady', handleMavaReady);

            // Cleanup listener
            return () => {
                window.removeEventListener('mavaReady', handleMavaReady);
            };
        }
    }, [generalIsLoading, homepageIsLoading, featuresIsLoading]);

    const metaTags = generateMetaTags(homeTags?.tags, 'meta');
    const metaTitleTemplate = generateMetaTags(homeTags?.tags, 'titleTemplate');
    const metaDefaultTitleTemplate = generateMetaTags(homeTags?.tags, 'defaultTitle')?.[0];

    const content = (
        <>
            {generalIsLoading || homepageIsLoading || featuresIsLoading ? (
                <div style={{ height: '100vh', width: '100vw' }}>
                    <LoadingOverlay />
                </div>
            ) : (
                <>
                    {metaTitleTemplate && metaDefaultTitleTemplate && (
                        <Helmet
                            titleTemplate={'Ping Proxies - %s'}
                            defaultTitle={metaDefaultTitleTemplate || 'Ping Proxies'}
                            meta={metaTags}
                        />
                    )}

                    <Routes>
                        <Route path={ROUTES.base} element={<Layout />}>
                            <Route index element={<Home />} />
                            <Route path={`${ROUTES.product}/:productSlug`} element={<ProductPage />} />
                            <Route path={`${ROUTES.features}/:featuresSlug`} element={<Features />} />
                            <Route path={ROUTES.location} element={<MainProxyLocation />} />
                            <Route path={`${ROUTES.location}/:countrySlug`} element={<CurrentProxyLocation />} />
                            <Route path={ROUTES.company} element={<Company />} />
                            <Route path={ROUTES.pricing} element={<Pricing />} />
                            <Route path={ROUTES.about_us} element={<AboutUs />} />
                            <Route path={ROUTES.faq} element={<FAQPage />} />
                            <Route path={ROUTES.blog} element={<Blog />} />
                            <Route path={`${ROUTES.blog}/:postSlug`} element={<CurrentPost />} />
                            <Route path={ROUTES.terms_and_conditions} element={<TermsAndConditions />} />
                            <Route path={ROUTES.privacy_policy} element={<PrivacyPolicy />} />
                            <Route path={ROUTES.refund_policy} element={<RefundPolicy />} />
                            <Route path={ROUTES.landing_page + '/:landingPageSlug'} element={<LandingPage />} />
                            <Route path={ROUTES.use_cases + '/:useCasesSlug'} element={<UseCases />} />
                            <Route path={ROUTES.brand_assets} element={<BrandAssets />} />
                            <Route path={ROUTES.not_found} element={<FZF />} />
                            <Route path="*" element={<NotFoundWrapper />} />
                        </Route>
                    </Routes>
                </>
            )}
        </>
    );

    return process.env.REACT_APP_BASIC_AUTH_PASSWORD ? <BasicAuth>{content}</BasicAuth> : content;
};

export default App;
