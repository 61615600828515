import './styles.scss';

import { useRef } from 'react';
import MainMarkGradient from 'assets/images/brand/main-mark-gradient.svg';
import MainMarkWhite from 'assets/images/brand/alternative-mark-white.svg';
import MainMarkBlack from 'assets/images/brand/alternative-mark-black.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { downloadAssets } from 'helpers';
import { useAnimation } from 'hooks/useAnimation';

const MarkSection = ({ isLoading }) => {
    const container = useRef();
    useAnimation({
        items: ['.brand-assets-mark__title', '.brand-assets-mark__description', '.brand-assets-mark-item'],
        container,
        dependencies: [isLoading],
    });

    const data = {
        title: 'Mark',
        description: 'Please use this symbol only where space is limited.',
        logos: [
            {
                title: 'Main mark version - gradient',
                logo: MainMarkGradient,
                type: 'white',
            },
            {
                title: 'Alternative mark version - white',
                logo: MainMarkWhite,
                type: 'purple',
            },
            {
                title: 'Alternative mark version - black',
                logo: MainMarkBlack,
                type: 'gray',
            },
        ],
    };

    return (
        <section ref={container} className="inner-container brand-assets-mark">
            <h2 className="brand-assets-mark__title h2">{data.title}</h2>
            <p className="brand-assets-mark__description body1">{data.description}</p>
            <div className="brand-assets-mark__wrapper">
                {data.logos.map((item, index) => (
                    <div className={`brand-assets-mark-item ${item.type}`} key={index}>
                        <p className="title">{item.title}</p>
                        <img src={item.logo} alt="logo" className="logo" />
                        <button
                            aria-label="Download Logotype"
                            className="button-download"
                            onClick={() => downloadAssets({ paths: [item.logo] })}
                        >
                            <Download />
                        </button>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default MarkSection;
