import './styles.scss';
import MainLogoBlack from 'assets/images/brand/main-logo-black.svg';
import MainLogoWhite from 'assets/images/brand/main-logo-white.svg';
import AlternativeLogoWhite from 'assets/images/brand/alternative-logo-white.svg';
import AlternativeLogoBlack from 'assets/images/brand/alternative-logo-black.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';

import { useRef } from 'react';
import { downloadAssets } from 'helpers';
import { useAnimation } from 'hooks/useAnimation';

const LogotypeSection = ({ isLoading }) => {
    const container = useRef();
    useAnimation({
        items: ['.brand-assets-logotype__title', '.brand-assets-logotype__description', '.brand-assets-logotype-item'],
        container,
        dependencies: [],
    });

    const data = {
        title: 'Logotype',
        description:
            'When placing our logo on a dark background, please use the inverse white version which we designed specifically for this type of application.',
        logos: [
            {
                title: 'Main logo version - black',
                logo: MainLogoBlack,
                type: 'white',
            },
            {
                title: 'Main logo version - white',
                logo: MainLogoWhite,
                type: 'dark-blue',
            },
            {
                title: 'Alternative logo version - white',
                logo: AlternativeLogoWhite,
                type: 'purple',
            },
            {
                title: 'Alternative logo version - black',
                logo: AlternativeLogoBlack,
                type: 'white',
            },
        ],
    };

    return (
        <section ref={container} className="inner-container brand-assets-logotype">
            <h2 className="brand-assets-logotype__title h2">{data.title}</h2>
            <p className="brand-assets-logotype__description body1">{data.description}</p>
            <div className="brand-assets-logotype__wrapper">
                {data.logos.map((item, index) => (
                    <div className={`brand-assets-logotype-item ${item.type}`} key={index}>
                        <p className="title">{item.title}</p>
                        <img src={item.logo} alt="logo" className="logo" />
                        <button
                            aria-label="Download Logotype"
                            className="button-download"
                            onClick={() => downloadAssets({ paths: [item.logo] })}
                        >
                            <Download />
                        </button>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default LogotypeSection;
