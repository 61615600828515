import { createSlice, createSelector } from '@reduxjs/toolkit';
import { createRequest } from 'api/helpers';
import { getLocations, getCurrentLocations, getLocationsPage, getContinents } from 'api/LocationPage/index';

export const fetchLocations = createRequest('fetchLocations', getLocations);
export const fetchContinents = createRequest('fetchContinents', getContinents);

export const fetchLocationsPage = createRequest('fetchLocationsPage', getLocationsPage, (_, { getState }) => {
    const { attributes } = getState().locationsState.locationsPage.data;
    return !attributes;
});

export const fetchCurrentLocation = createRequest('fetchCurrentLocation', getCurrentLocations);


const LocationsSlice = createSlice({
    name: 'LocationsSlice',
    initialState: {
        locations: [],
        locationsIsLoading: false,
        continents: {},
        continentsIsLoading: false,
        locationsPage: {
            data: {
                attributes: null,
            },
        },
        locationsPageIsLoading: false,
        currentLocation: {
            data: {
                attributes: null,
            },
        },
        currentLocationIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchLocations.pending, state => {
            state.locationsIsLoading = true;
        });
        builder.addCase(fetchLocations.fulfilled, (state, action) => {
            state.locationsIsLoading = false;
            state.locations = action.payload.data.sort((a, b) => {
                if (a.attributes.top && !b.attributes.top) {
                    return -1;
                }
                return 0;
            });
        });
        builder.addCase(fetchContinents.pending, state => {
            state.continentsIsLoading = true;
        });
        builder.addCase(fetchContinents.fulfilled, (state, action) => {
            state.continentsIsLoading = false;
            state.continents = action.payload;
        });
        builder.addCase(fetchLocationsPage.pending, state => {
            state.locationsPageIsLoading = true;
        });
        builder.addCase(fetchLocationsPage.fulfilled, (state, action) => {
            state.locationsPageIsLoading = false;
            state.locationsPage = action.payload;
        });
        builder.addCase(fetchCurrentLocation.pending, state => {
            state.currentLocationIsLoading = true;
        });
        builder.addCase(fetchCurrentLocation.fulfilled, (state, action) => {
            state.currentLocation = action.payload;
            state.currentLocationIsLoading = false;
        });
    },
});

// All Locations for preview
export const selectLocations = createSelector([state => state.locationsState], data => data?.locations || []);

// Top locations for preview
export const selectTopLocations = createSelector([selectLocations], locations => locations?.filter(location => location?.attributes?.top) || []);

// All Continents
export const selectContinents = createSelector([state => state.locationsState], data => data?.continents || []);


//Locations Page
export const selectLocationsPage = createSelector(
    [state => state.locationsState.locationsPage],
    locationsPage => locationsPage.data.attributes,
);
export const selectLocationsPageHero = createSelector([selectLocationsPage], data => data?.hero || {});
export const selectLocationsPageLocations = createSelector([selectLocationsPage], data => data?.locations || {});
export const selectLocationsPageList = createSelector([selectLocationsPage], data => data?.list_of_countries || {});
export const selectLocationsPageCTA = createSelector([selectLocationsPage], data => data?.cta || {});
export const selectLocationsPageTags = createSelector([selectLocationsPage], data => data?.meta_tags || {});



//Current Locations Page
export const selectCurrentLocations = createSelector(
    [state => state.locationsState.currentLocation],
    currentLocation => currentLocation.data[0]?.attributes,
);

export const selectCurrentLocationsHero = createSelector([selectCurrentLocations], data => data?.hero || {});
export const selectCurrentLocationsProducts = createSelector([selectCurrentLocations], data => data?.products_plans || {});
export const selectCurrentLocationsLocations = createSelector([selectCurrentLocations], data => data?.proxy_locations || {});
export const selectCurrentLocationsSingle = createSelector([selectCurrentLocations], data => data?.single_feature || []);
export const selectCurrentLocationsReviews = createSelector([selectCurrentLocations], data => data?.reviews || {});
export const selectCurrentLocationsBenefits = createSelector([selectCurrentLocations], data => data?.benefits || {});
export const selectCurrentLocationsFAQ = createSelector([selectCurrentLocations], data => data?.faq || {});
export const selectCurrentLocationsCTA = createSelector([selectCurrentLocations], data => data?.cta || {});
export const selectCurrentLocationsTags = createSelector([selectCurrentLocations], data => data?.meta_tags || {});



//Loaders
export const selectLocationsIsLoading = createSelector([state => state.locationsState], data => data.locationsIsLoading);
export const selectLocationsPageIsLoading = createSelector([state => state.locationsState], data => data.locationsPageIsLoading);
export const selectCurrentLocationIsLoading = createSelector([state => state.locationsState], data => data.currentLocationIsLoading);

export default LocationsSlice.reducer;
