import { useEffect, useRef, useState } from 'react';
import './styles.scss';
import Label from 'components/Label';
import Button from 'components/common/Buttons';
import { getTextContent, getTextRecursively } from 'helpers';
import { useAnimation } from 'hooks/useAnimation';
import { Helmet } from 'react-helmet';

const RadioFAQ = ({ isLoading, data }) => {
    const { tag, title, help_button_link, help_button_text, help_title, questions } = data;
    const container = useRef();
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [contentHeight, setContentHeight] = useState(0);
    const [fadeIn, setFadeIn] = useState(false);

    const handleItemClick = index => {
        if (activeItemIndex !== index) {
            setActiveItemIndex(index);
            setFadeIn(false);
        }
    };

    useEffect(() => {
        if (container.current && !isLoading) {
            // @ts-ignore
            const contentElement = container.current.querySelector('.faq-radio-content-body');
            if (contentElement) {
                setContentHeight(contentElement.scrollHeight);
                setTimeout(() => setFadeIn(true), 100);
            }
        }
    }, [container, activeItemIndex, isLoading]);

    useAnimation({
        items: ['.faq-radio h2', '.faq-radio-menu__item', '.faq-radio-content-body', '.faq-radio__help'],
        container,
        dependencies: [isLoading],
    });

    if (!Object.keys(data).length) {
        return null;
    }

    return (
        <>
            <Helmet
                script={[
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            '@context': 'http://schema.org',
                            '@type': 'FAQPage',
                            mainEntity: questions?.map(item => ({
                                '@type': 'Question',
                                name: item.question,
                                acceptedAnswer: {
                                    '@type': 'Answer',
                                    text: item.text.map(text => getTextRecursively(text)).join(' '),
                                },
                            })),
                        }),
                    },
                ]}
            />
            <section ref={container} className="inner-container faq-radio">
                <Label text={tag} />
                <h2>{getTextContent(title)}</h2>
                <div className="faq-radio__container">
                    <div className="faq-radio-menu">
                        {questions?.map((item, index) => (
                            <div
                                key={index}
                                className={`faq-radio-menu__item ${activeItemIndex === index ? 'active' : ''}`}
                                onClick={() => handleItemClick(index)}
                            >
                                <input
                                    type="radio"
                                    style={{ display: 'none' }}
                                    checked={activeItemIndex === index}
                                    readOnly
                                />
                                <label className="radio-button" htmlFor={`faq-radio-${index}`} />
                                <span className="description2">{item.question}</span>
                            </div>
                        ))}
                    </div>
                    <div className="faq-radio-content">
                        <div
                            className={`faq-radio-content-body ${fadeIn ? 'fade-in' : ''}`}
                            style={{ maxHeight: `${contentHeight}px` }}
                        >
                            {questions && getTextContent(questions[activeItemIndex].answer)}
                        </div>

                        <div className="faq-radio__help">
                            <span className="description2">{help_title}</span>
                            <Button to={help_button_link} text={help_button_text} type="white" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RadioFAQ;
