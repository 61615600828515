import HeroCrumbs from 'components/Sections/Hero/HeroCrumbs';
import { useAnimation } from 'hooks/useAnimation';
import { useRef } from 'react';
import './styles.scss';
import DownloadButton from 'components/common/Buttons/DownloadButton';
import MainLogoBlack from 'assets/images/brand/main-logo-black.svg';
import MainLogoWhite from 'assets/images/brand/main-logo-white.svg';
import AlternativeLogoWhite from 'assets/images/brand/alternative-logo-white.svg';
import AlternativeLogoBlack from 'assets/images/brand/alternative-logo-black.svg';
import MainMarkGradient from 'assets/images/brand/main-mark-gradient.svg';
import MainMarkWhite from 'assets/images/brand/alternative-mark-white.svg';
import MainMarkBlack from 'assets/images/brand/alternative-mark-black.svg';
import { downloadAssets, generateMetaTags } from 'helpers';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { selectBrandAssetsTags } from 'store/slices/GeneralSlice';

const HeroBrand = ({ isLoading }) => {
    const container = useRef();
    useAnimation({
        items: [
            '.hero-bredcrumbs',
            '.brand-assets-hero .title',
            '.brand-assets-hero .description',
            '.button-container',
            '.instruction',
        ],
        container,
        dependencies: [],
    });

    const handleDownload = () => {
        const fullKit = [
            MainLogoBlack,
            MainLogoWhite,
            AlternativeLogoWhite,
            AlternativeLogoBlack,
            MainMarkGradient,
            MainMarkWhite,
            MainMarkBlack,
        ];
        downloadAssets({paths: fullKit});
    };

    const BrandMetaTags = useSelector(selectBrandAssetsTags);

    const metaTags = generateMetaTags(
        BrandMetaTags?.tags,
        'meta',
        `Ping Proxies - Brand Assets}`,
        'Explore the elements of the Ping Proxies brand',
    );
    const metaTitle = generateMetaTags(BrandMetaTags?.tags, 'title');

    return (
        <>
            <Helmet
                title={metaTitle ? `${metaTitle}` : 'Explore the elements of the Ping Proxies brand'}
                meta={metaTags}
            />
            <section ref={container} className="inner-container brand-assets-hero">
                <HeroCrumbs page={'Brand assets'} />
                <h1 className="title h1">
                    Explore the elements <br /> of the Ping Proxies brand
                </h1>
                <p className="description body1">
                    Our complete kit includes everything you need to better integrate our brand into all media
                </p>
                <div className="button-container">
                    <DownloadButton text={'Download full kit'} onClick={handleDownload} />
                </div>
                <p className="instruction body4">Logo and Mark in: .png and .svg</p>
            </section>
        </>
    );
};

export default HeroBrand;
